import * as React from "react"

function Logo(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 100 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="logo-color" d="M.35,88.77S2.67,52.56,20,43.05l10.82-4.34s12.84-3.8,42.28,20.9c0,0-.76-38.27,7-47.5,0,0,5.67-9,19.82-11.83,0,0-17.55-3.37-26.61,13.46H8.07V47.13A78,78,0,0,0,.35,88.77Z"/><polygon className="logo-color" points="23.06 111.73 8.07 111.73 8.07 91.65 8.46 87.03 23.06 111.73"/><path className="logo-color" d="M10.47,75.9,29.6,111.73H48.47L19.28,53.1S12.49,64.77,10.47,75.9Z"/><path className="logo-color" d="M24.51,49.68,55,111.73H70.81l1.07-7.87L49,57.71S32,47.89,24.51,49.68Z"/><polygon className="logo-color" points="57.79 62.6 72.38 91.65 73.14 73.18 57.79 62.6"/><path className="logo-color" d="M85.72,111.46H99.81V90.56L87.48,82.69S88,103.86,85.72,111.46Z"/><path className="logo-color" d="M99.81,76.44V54.73L88,29.48S87,52,86.72,68.57Z"/><polygon className="logo-color" points="99.81 38.71 99.81 13.67 90 13.67 89.49 16.45 99.81 38.71"/></g></g>
    </svg>
  )
}

export default Logo
