import { graphql, useStaticQuery, Link } from "gatsby"
import * as React from "react"
import slugify from "@sindresorhus/slugify"
import { navStyle, navLink, activeLink } from "./navigation.module.css"

export function Navigation({ className }) {
  const {
    allShopifyProduct: { productTypes },
  } = useStaticQuery(graphql`
    query {
      allShopifyProduct {
        productTypes: distinct(field: productType)
      }
    }
  `)

  return (
    <nav className={[navStyle, className].join(" ")}>
      <Link
        key="All"
        className={navLink}
        to="/products/"
        activeClassName={activeLink}
      >
        All Products
      </Link>
      {/* <Link
        key="Collections"
        className={navLink}
        to="/collections/"
        activeClassName={activeLink}
      >
        Collections
      </Link> */}
      {productTypes.map((name) => (
        <Link
          key={name}
          className={navLink}
          to={`/products/${slugify(name)}`}
          activeClassName={activeLink}
        >
          {name}
        </Link>
      ))}
      <Link
      key="Services"
      className={navLink}
      to="/services/"
      activeClassName={activeLink}
      >
        Services
      </Link>
      <Link
      key="Contact us"
      className={navLink}
      to="/contact/"
      activeClassName={activeLink}
      >
        Contact us
      </Link>
    </nav>
  )
}
