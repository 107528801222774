import * as React from 'react'
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe"

export function AboutSection() {
    return (
        <section>
        <div className="container">
          <div className="flex-section">
            <div className="half">
              <div className="aboutSection">
                  <h3 className="tinyTitle">Chain of custody</h3>
                  <p>Any relationship analysis performed by our laboratory, be it siblingship, family study, paternity, maternity, etc. CAN be an “AABB Accredited” test as long as the samples are collected via chain of custody procedures.</p>
                  <p>Samples must also be collected via chain of custody procedures for any analysis performed by our laboratory to be considered court-admissible.</p>
                  <p>Home kits are self-collected and therefore by definition cannot meet AABB standards nor considered court-admissible.</p>
                  <p>Call for more information. Our DNA collection analysts are happy to answer your questions. </p>
              </div>
              <div className="aboutSection">
                <h3 className="tinyTitle">About us</h3>
                <p>
                Independent Forensics is a forensic DNA laboratory that provides DNA testing and conceives, develops, perfects, manufactures, markets and sells new products to forensic laboratories worldwide. Our staff has over 60 combined years of experience in DNA, molecular biology, forensics, paternity, human identification and related scientific fields.
                </p>
                <p>
                We understand how important it is to get fast and accurate DNA results. Our tests are efficiently processed and analyzed at our <a className="text-underlined" href="https://www.ifi-test.com/accreditation/" rel="noopener noreferrer">accredited laboratory</a> in Lombard, Illinois. Once samples are received, we start processing them the same day to get you fast results in 3-5 business days. Need faster results? Expedited service is available for an additional charge.
                </p>
              </div>
            </div>
            <div className="half">
              <div className="aboutSection">
              <h3 className="tinyTitle">We cannot sell home kits to NY residents. </h3>
              <h3 className="tinyTitle">Order Processing</h3>
              <p>
              Online orders for our Home Collection kits placed during office hours are processed the same day. Orders placed during the weekend and/or holidays are processed the following business day.
              </p>
              <h3 className="tinyTitle">Payment Methods</h3>
              <p>
              At this time we are not accepting payments online. Our available payment methods during checkout are:
              </p>
              <ul>
                <li>Credit/Debit - A representative will contact you via phone to collect payment and answer any of your questions.</li>
                <li>Cash (In Person) - If you live in the area, you can stop by our office in Lombard IL and make a payment in person.</li>
              </ul>
              <h3 className="tinyTitle">Refunds</h3>
              <p>
                If you have any issues or concerns please call us at 708-234-1200.
              </p>
              <h3 className="tinyTitle">Shipping</h3>
              <ul>
                <li>USPS Free shipping - 5-7 days. Includes return stamps, tracking not available</li>
                <li>FedEx Expedited Delivery - Starting at $25</li>
              </ul>
              </div>
              <div className="aboutSection">
                <h3 className="tinyTitle">Online Presence</h3>
                  <ul style={{listStyle: "none", display: "flex", justifyContent: "space-evenly", padding: "15px 0"}}>
                    <li><a style={{display: "flex", alignItems: "center"}} href="https://www.facebook.com/IndependentForensicsDNA" rel="noopener noreferrer nofollow"><FaFacebookSquare /> Facebook</a></li>
                    <li><a style={{display: "flex", alignItems: "center"}} href="https://www.linkedin.com/company/independent-forensics" rel="noopener noreferrer nofollow"><FaLinkedin /> LinkedIn</a></li>
                    <li><a style={{display: "flex", alignItems: "center"}} href="https://www.ifi-test.com/" rel="noopener noreferrer nofollow"><FaGlobe /> Main Site</a></li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}