import * as React from "react"
import { Link } from "gatsby"
import { StoreContext } from "../context/store-context"
import Logo from "../icons/logo"
import { Navigation } from "./navigation"
import { CartButton } from "./cart-button"
import SearchIcon from "../icons/search"
import { Toast } from "./toast"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import { FaPhone } from "@react-icons/all-files/fa/FaPhone"
import {
  header,
  container,
  logo as logoCss,
  searchButton,
  nav,
  tinyHeader
} from "./header.module.css"

export function Header({isLandingPage}) {
  const { checkout, loading, didJustAddToCart } = React.useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  return (
    <>
      <section className={tinyHeader}>
        <div className="container-sm">
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <a style={{flex: "1", display: "flex", alignItems: "center", cursor: "pointer"}} href="https://g.page/IndependentForensics?share" rel="noopener noreferrer nofollow"><FaMapMarkerAlt /><h5 style={{fontSize: "15px", marginLeft: "5px"}}>Lombard IL</h5></a>
            <div style={{flex: "1"}}></div>
            <a style={{flex: "1", display: "flex", justifyContent: "end", alignItems: "center", cursor: "pointer"}} href="tel:7082341200"><FaPhone /><h5 style={{fontSize: "15px", marginLeft: "5px"}}>Call Now</h5></a>
          </div>
        </div>
      </section>
      <section className={container}>
        <div className="container-0">
          <header className={header}>
            <Link to="/" className={logoCss}>
              <Logo />
            </Link>
            {!isLandingPage && <Navigation className={nav} />}
            {!isLandingPage && <CartButton quantity={quantity} />}
          </header>
          <Toast show={loading || didJustAddToCart}>
            {!didJustAddToCart ? (
              "Updating…"
            ) : (
              <>
                Added to cart{" "}
                <svg
                  width="14"
                  height="14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                    fill="#fff"
                  />
                  <path
                    d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                    fill="#fff"
                  />
                  <path
                    d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                    fill="#fff"
                  />
                </svg>
              </>
            )}
          </Toast>
        </div>
      </section>
    </>
  )
}
