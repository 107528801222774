import * as React from "react"
import propTypes from 'prop-types'
import { SkipNavContent, SkipNavLink } from "./skip-nav"
import { Header } from "./header"
import { Footer } from "./footer"
import { Seo } from "./seo"

export function Layout({ children, isLandingPage }) {
  return (
    <div>
      <Seo />
      <SkipNavLink />
      <Header isLandingPage={isLandingPage} />
      <SkipNavContent>{children}</SkipNavContent>
      <Footer />
    </div>
  )
}


Layout.propTypes = {
  isLandingPage: propTypes.bool
}

Layout.defaultProps = {
  isLandingPage: false
}
