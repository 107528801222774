import * as React from "react"
import Logo from "../icons/logo"
import {AboutSection} from "./about-section"
import {
  footerStyle,
  copyright,
  blurb,
  logos,
} from "./footer.module.css"

export function Footer() {
  const address = "500 Waters Edge St 210 Lombard IL 60148"
  const mainwebsite = "https://www.ifi-test.com/"
  return (
    <>
      <AboutSection />
      <footer className={footerStyle}>
      <div className={blurb}>
        <div className={logos}>
          <Logo />
        </div>
      </div>
      <div className={copyright}>
        <a href={mainwebsite} rel="noopener noreferrer">Independent Forensics LLC Copyright &copy; 2002-{new Date().getFullYear()} · All rights reserved. {address}</a>
      </div>
    </footer>
    </>
  )
}
